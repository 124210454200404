.table-cell {
  border: none !important;
  width: fit-content;
  padding: 0 !important;
}

.table-cell-density-standard {
  padding: 3px 5px !important;
}

.table-cell-density-comfortable {
  padding: 10px !important;
}

.table-cell-density-condensed {
  padding: 2px !important;
}

.table-filter {
  background-color: #f5f5f533;
  font-size: 0.8rem !important;
  padding: 0.25rem 0.5rem;
}