@keyframes modalNotificationsDisappear {
  to {
    box-shadow: none;
    transform: translateY(100%);
    background-color: transparent;
    opacity: 0;
    max-height: 0px;
  }
}

@keyframes modalNotificationsAppear {
  from {
    transform: translateY(25%);
    background-color: transparent;
    opacity: 0;
    max-height: 0px;
  }
}

.modal-notification {
  width: 100%;
  box-shadow: 1px 1px 150px #00000071, 1px 1px 10px #0000001c;
  border-radius: var(--borderRadius);
  z-index: 999999999;
  animation: modalNotificationsAppear 0.2s ease, modalNotificationsDisappear 0.4s ease 3.6s;
}

.modal-notification-sticky {
  animation: none !important;
}

.modal-notification-disappearing {
  animation: modalNotificationsDisappear 0.4s ease 3.6s !important;
}