.side-nav-link {
  width: 100%;
  color: white;
  user-select: none;
  border-radius: var(--sideNavBorderRadius);
  overflow: hidden;
  text-decoration: none;
}

.side-nav-link-active .icon {
  color: white !important;
}

@media (any-pointer) {
  .side-nav-link:hover {
    cursor: pointer;
    color: white;
  }

  .side-nav-link:hover .icon {
    color: white !important;
  }
}

@media screen and (max-width: 1000px) {

  .side-nav-link {
    height: 50px;
  }
}