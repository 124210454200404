@keyframes logoAppear {
  from {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.page-with-top-nav {
  height: calc(100vh - var(--topNavHeight)) !important;
}

.page {}

.page-fullscreen {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  left: 0;
  top: 0;
  position: fixed !important;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.page-colored {
  background-color: var(--primary);
}

.page-container {
  height: 100vh;
  display: flex;
  background-color: white;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 0px 40px #00000087;
}

.page-content {
  border-top: 1px solid #002E7F33;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-fullscreen .page-content {
  padding-top: 0;
}

.page-logo-background-image {
  height: auto;
  width: 100%;
}

.page-logo-background-container {
  opacity: 0.1;
}

.page-content-sidebar-visible {
  width: 100%;
}

.page-right-sidebar {
  border-top: 1px solid #F2F5F9 !important;
  background-color: #F2F5F9;
  z-index: 999;
  width: fit-content;
  align-self: start;
  width: 100%;
  padding-right: var(--contentMargin) !important;
}

.page-right-sidebar-wide {
  max-width: 95%;
  width: 100%;
}

.page-right-sidebar-narrow {
  max-width: max(450px, 40%);
}

.page-right-sidebar-content {
  padding-top: 36px;
  padding-left: 24px;
  height: 100%;
}


.page-right-sidebar-narrow-expanded {
  max-width: max(400px, 40%);
}

.page-right-sidebar-expanded {
  min-width: max(400px, 40%);
}

.page-right-sidebar-wide-expanded {
  max-width: 100vw;
}

.page-right-sidebar-content {
  transition: 0.15s ease;
}

.page-right-sidebar-content-expanded {
  max-width: calc(95vw - 30px);
}

.page-right-sidebar-toggle {
  border-top: 1px solid var(--grey) !important;
  background-color: var(--grey);
}

.page-right-sidebar-toggle {
  width: 30px;
}

.page-right-sidebar-narrow-expanded {
  max-width: 50vw;
  width: 100%;
}

.page-right-sidebar {
  width: fit-content;
  align-self: start;
  max-width: 100%;
}

.page-right-sidebar-collapsed {
  max-width: 30px;
  width: 100%;
}

.page-right-sidebar-content-collapsed {
  max-width: 0;
  overflow: hidden;
  visibility: hidden;
  display: none;
}

.page-right-sidebar-toggle {
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  min-width: 30px;
}

.page-right-sidebar-content-expanded {
  overflow: hidden;
}

@media screen and (max-width: 768px) {

  .page-right-sidebar-expanded {
    max-width: 100vw;
    min-width: 100vw;
  }

  .page-right-sidebar-content {
    padding-left: 0;
  }

  .page-content {
    width: 100%;
    padding-left: var(--contentMargin);
    padding-right: var(--contentMargin);
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .page-content {
    width: calc(100% - var(--contentMargin) * 2) !important;
    margin-left: var(--contentMargin);
  }

}

@media screen and (min-width: 1000px) {
  .page-container {
    padding-left: var(--bodyMargin);
    border-top-left-radius: var(--pageBorderRadius);
  }
}

@media screen and (min-width: 1500px) {
  .page-content {
    width: calc(100% - var(--contentMargin)) !important;
  }

  .page-content-with-sidebar {
    padding-right: 30px;
  }
}

@media screen and (max-width: 1500px) {
  .page-content-with-sidebar {
    padding-right: calc(var(--bodyMargin));
  }

  .page-right-sidebar {
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 1800px) {

  .page-right-sidebar {
    transition: box-shadow 0.25s ease;
  }

  .page-right-sidebar-collapsed:hover,
  .page-right-sidebar-expanded {
    box-shadow: -10px 10px 20px var(--backgroundDarker);
  }

  .page-right-sidebar-expanded {
    box-shadow: -10px 5px 10px var(--backgroundDarker);
    position: absolute;
    right: 0;
  }
}


@media (any-pointer) {
  .page-right-sidebar-toggle:hover {
    cursor: pointer;
    background-color: var(--muted);
    border-top-color: var(--muted) !important;
  }
}