.table-row {
  background-color: white;
  border-bottom: 1px solid #00000007;
}

@media (any-pointer) {
  .table-row-clickable:hover {
    background-color: #f3f3fF !important;
    cursor: pointer;
  }
}