.verification-method-select {
    border: 1px solid var(--grey);
    background-color: var(--grey);
    border-radius: var(--borderRadius);
    transition: all 0.15s ease;
    color: var(--primary);
}

.verification-method-icon {
    transition: transform 0.05s ease-in;
}

.verification-method-icon-disabled {
    color: var(--secondary);
    background-color: var(--muted);
}

.verification-method-select-disabled {
    background-color: var(--muted);
    border-color: var(--muted);
}

.verification-method-select-disabled .verification-select-text {
    color: var(--secondary);
}

.verification-method-select-loading {
    background-color: aliceblue !important;
}

@media (any-pointer) {
    .verification-method-select:hover:not(.verification-method-select-disabled) {
        cursor: pointer;
        background-color: var(--secondary);
        border-color: var(--primary);
        color: var(--primary);
    }

    .verification-method-select:hover:not(.verification-method-select-disabled) .verification-method-icon {
        transform: scale(1.1);
    }

    .verification-method-select:hover:not(.verification-method-select-disabled) .verification-select-text {
        color: var(--primary);
    }
}