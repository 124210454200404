.imposter-session-content {
  border: 10px solid var(--error);
}

.app-content-imposter-session {
  overflow: hidden;
}

@media screen and (min-width: 1000px) {
  .app-content-sidebar-expanded {
    max-width: calc(100% - var(--sidenavWidthDesktop) + 2px) !important;
  }

  .app-content {
    max-width: calc(100% - 24px);
  }
}