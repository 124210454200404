.copy-to-clipboard-btn-text,
.copy-to-clipboard-btn-text span {
  transition: all 0.15s ease;
  color: var(--backgroundDarkest) !important;
  user-select: none !important;
}

@media screen and (any-pointer) {
  .copy-to-clipboard-btn-text:hover,
  .copy-to-clipboard-btn-text:hover span  {
    color: black !important;
    cursor: pointer;
  }
}