.imposter-session-user-indicator {
    position: fixed;
    width: 100vw;
    height: 10px;
    top: 0;
    left: 0;
    background-color: var(--error);
    z-index: 99999999;
}

.imposter-session-user-indicator-content {
    position: fixed;
    top: 5px;
    width: 450px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    background-color: var(--error);
    z-index: 99999999;
    border-bottom-right-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
}

.imposter-session-expander {
    background-color: var(--error);
    padding: 10px;
    width: 150px;
    border-radius: var(--borderRadius);
}