.ticket-grid {
    width: 100%;
    max-width: 100%;
    display: grid;
    column-gap: 19px;
    row-gap: 25px;
}

@media screen and (min-width: 1700px) {
    .ticket-grid {
        grid-template-columns: repeat(3, minmax(250px, 1fr));
    }
}

@media screen and (min-width: 1000px) and (max-width: 1700px) {
    .ticket-grid {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 1000px) {
    .ticket-grid {
        grid-template-columns: 1fr;
    }
}