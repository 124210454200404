.calendar-day-hour-add-task-icon {
  display: none;
}

.calendar-day-hour {
  transition: all 0.15s ease;
  opacity: 0.2;
}

.calendar-day-hour:hover {
  cursor: pointer;
  background-color: var(--muted);
}

.calendar-day-hour:hover .calendar-day-hour-add-task-icon {
  display: flex;
}