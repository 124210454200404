.icon-clickable {
    transition: all 0.15s ease;
}

.icon-label {
    user-select: none;
}

.icon-tooltip {
    z-index: 99999999;
    background-color: white;
    position: fixed;
    min-width: 100px;
    min-height: 20px;
    box-shadow: 0px 0px 8px var(--shadow);
    border-radius: var(--borderRadius);
}

.icon-tooltip-bottom {
    top: calc(100% + 10px);
}

.icon-tooltip-top {
    bottom: calc(100% + 10px);
}

@media (any-pointer) {
    .icon-clickable:hover {
        cursor: pointer;
    }

    .icon-clickable:hover .icon {
        color: var(--secondary) !important;
    }
}