.pill-variant-pill,
.pill-content-pill {
  min-height: 28px;
}

.pill-inner {
  border-radius: var(--borderRadius);
  overflow: hidden;
}

.pill {
  width: fit-content;
  transition: all 0.15s ease;
  position: relative;
}

.pill-content-badge {
  min-height: 28px;
  min-width: 28px;
}

.pill-content-pill {
  padding: 0.25em 1em;
}

.pill-content {
  transition: all 0.15s ease;
}

.pill-divider {
  width: 1px;
  height: 100%;
}

.pill-bg {
  z-index: 0;
}

.pill-clickable {
  user-select: none;
}

@media (any-pointer) {
  .pill-clickable:hover {
    cursor: pointer;
  }

  .pill-clickable:hover .pill-bg {
    opacity: 0.8;
  }
}