.button {
  outline: none;
  border-radius: var(--borderRadius);
  overflow: hidden;
  height: fit-content !important;
  width: fit-content;
  background-color: transparent;
  width: fit-content;
  min-width: fit-content;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  user-select: none;
  text-decoration: none;
}

.button-solid,
.button-subtle,
.button-outline {
  padding: .275em 1.25em;
}

.button-hyperlink {
  text-decoration: underline !important;
}

.button-text {
  height: 100%;
  text-decoration: none !important;
}

.button:disabled {
  filter: saturate(0.2);
  opacity: 0.3;
}

.button-background {
  opacity: 0.9;
}

@media screen and (any-pointer) {

  .button:active {
    outline: none;
  }

  .button-solid:hover:not(:disabled) {
    background-color: #000000;
    filter: none;
    cursor: pointer;
  }

  .button-solid:hover:not(:active):not(:disabled) .button-background {
    opacity: 1;
  }

  .button-solid:active:not(:disabled) .button-background {
    opacity: 0.8;
  }
}