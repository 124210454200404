.expandable-item-icon-container {
  width: 66px;
}

.expandable-item-text {
  font-size: 14px;
}

@media (any-pointer) {
  .expandable-item-clickable:hover:not(.expandable-item-expanded) {
    cursor: pointer;
    background-color: var(--backgroundLighter);
  }
}