.top-nav {
  width: calc(100% - var(--contentMargin)) !important;
  flex: 0 0 var(--topNavHeight);
  height: var(--topNavHeight);
}

@media screen and (min-width: 1000px) {
  .sidenav-expander-icon {
    display: none;
    visibility: hidden;
  }

  .top-nav {
    width: calc(100% - var(--contentMargin)) !important;
  }
}

@media screen and (max-width: 1000px) {

  .top-nav {
    width: calc(100% - var(--contentMargin) * 2) !important;
    margin-left: var(--contentMargin);
  }

  .sidenav-expander-icon {
    display: flex;
  }
}