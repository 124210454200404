.table-wrapper {
    border: none !important;
    background-color: white;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
}

.table>:not(caption)>*>* {
    border: none !important;
}

.table-wrapper-border {
    border-radius: var(--borderRadius);
}

.table {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.table .table-row:nth-child(even) {
    background-color: #fafbff;
} 
