.page-indicator {
  border-radius: 50px;
  width: 24px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  background-color: var(--backgroundDarker);
  cursor: pointer;
  transition: all 0.3s ease;
}


.page-indicator-active,
.page-indicator:hover {
  font-weight: bold;
  width: 36px;
  background-color: var(--primary);
  color: white;
}