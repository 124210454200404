.table-cell {
  border: none !important;
  width: fit-content;
  padding: 0 !important;
}

.table-cell-density-header {
  padding: 8px 5px !important;
}

.table-cell-header {
  top: 0;
  z-index: 9;
}

.table-filter {
  background-color: #f5f5f533;
  font-size: 0.8rem !important;
  padding: 0.25rem 0.5rem;
}