.ticket-notes {
  background-color: var(--grey);
  border-top-right-radius: var(--borderRadius);
  border-top-left-radius: var(--borderRadius);
}

.ticket-notes-expander-handle {
  overflow: hidden;
  transition: all 0.15s ease;
  border-top-right-radius: var(--borderRadius);
  border-top-left-radius: var(--borderRadius);
  height: 32px;
}

.ticket-notes-expander-handle-expanded {
  background-color: var(--grey);

}

.ticket-expander-icon {
  transition: all 0.3s ease;
}

.ticket-expander-icon-expanded {
  transform: rotate(180deg);
}

.ticket-notes-expander-text {
  text-transform: uppercase;
}

.ticket-notes-container {
  transition: all 0.3s ease;
  height: 70vh;
  overflow-y: auto;
}

.ticket-notes-container-hidden {
  max-height: 0;
  opacity: 0;
}

.ticket-notes-container-expanded {
  max-height: 50vh;
}


@media (any-pointer) {
  .ticket-notes-expander-handle:hover {
    background-color: var(--muted);
    cursor: pointer;
  }
}