.sidenav-content {
    height: fit-content;
    width: 100%;
}

.sidenav-content-container {
    overflow-y: auto;
    overflow-x: hidden;
}

.sidenav-container {
    background-color: var(--primary);
    width: 100%;
    padding-left: var(--contentMargin);
}

.sidebar-expander-icon-desktop {
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (min-width: 1000px) {

    .sidebar-toggle-mobile {
        display: none !important;
        visibility: hidden !important;
    }

    .sidenav-header {
        height: 20%;
    }
    
    .sidenav-container  { 
        max-width: var(--sidenavWidthDesktop);
        min-width: var(--sidenavWidthDesktop);
    }

    .sidenav-desktop-collapsed {
        min-width: 50px !important;
        max-width: 50px !important;
        padding-left: 0 !important;
        overflow: hidden;
    }

    .sidenav-desktop-collapsed .sidenav-content-container {
        display: none !important;
        visibility: hidden !important;
    }

    .sidenav-desktop-collapsed .sidenav-header {
        display: none !important;
        visibility: hidden !important;
    }
}

@media screen and (max-width: 1000px) {

    .sidebar-toggle-desktop {
        display: none !important;
        visibility: hidden !important;
    }

    .sidebar-toggle-desktop-collapsed {
        display: none;
        visibility: hidden;
    }

    .sidenav-container {
        position: absolute;
        height: 100%;
        top: 0; 
        left: 0;
        z-index: 100;
    }

    .sidenav-expanded {
        max-width: calc(100vw - 35px);
        width: 100%;
    }

    .sidenav-collapsed {
        max-width: 0;
        padding-left: 0 !important;
        overflow: hidden;
    }
}