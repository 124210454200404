.appointment {
  position: absolute;
}

.appointment-background {
  position: absolute;
  transition: all 0.3s ease;
  border-left-width: 2px;
  border-left-style: solid;
  background-color: var(--muted);
  width: 100%;
  height: 100%;
}

.appointment:hover {
  cursor: pointer;
}

.appointment:hover .appointment-background {
  opacity: 1;
}

.appointment-background-upcoming {
  border-left-color: var(--primary);
  background-color: var(--primary);
}

.appointment-background-past {
  opacity: .4;
  border-left-color: var(--secondary);
  background-color: var(--muted);
}

