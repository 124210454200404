.searchable-combo-box-input-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}


.searchable-combo-box-input {
  border-color: var(--muted) !important;
  color: var(--primary) !important;
  background-color: var(--backgroundLightest);
}

.searchable-combo-box-input::placeholder {
  color: var(--muted);
}

.searchable-combo-box-input:focus {
  background-color: white;
}

.searchable-combo-box-item-container {
  background-color: white;
}

.searchable-combo-box-items {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}