.date-time-picker-container {
  background-color: var(--background);
}

@media screen and (min-width: 568px) {
  .date-time-picker-container {
    height: 270px;
    overflow: hidden;
  }
}

@media screen and (max-width: 568px) {
  .date-time-picker-container {
    max-width: 300px;
  }
}