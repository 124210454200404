.multi-step-wizard {
    transition: all 0.3s ease;
    height: fit-content;
}

.multi-step-wizard-progress-container {
    height: 12px;
    bottom: 5px;
    z-index: 99999;
    left: 0;
    width: 100%;
    overflow: hidden;
    background-color: #F3F3F3;
    border-radius: var(--borderRadius);

}