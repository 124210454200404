.ticket-note {
  background-color: #eeec60;
  min-height: 100px;
  min-width: 200px;
  max-width: 400px;
  border-radius: var(--borderRadius);
}

.ticket-note-text {
  background-color: transparent;
  border: none;
  resize: none;
  width: 100%;
  height: fit-content;
  outline: none;
}

.ticket-note-form-expanded {
  margin-right: 0 !important;
  background-color: var(--muted);
  max-width: 200px;
}

.ticket-note-icon {
  transition: all 0.3s ease;
  transform: rotate(-45deg);
}

.ticket-note-icon~span {
  white-space: nowrap !important;
}

