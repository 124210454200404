@keyframes tooltipAppear {
    from {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes tooltipDisappear {
    to {
        opacity: 0;
    }
}

.tooltip-container {
    z-index: 99999999;
    background-color: white;
    position: fixed;
    min-height: 20px;
    max-width: 450px;
    border-radius: var(--borderRadius);
    border: 1px solid var(--backgroundDarker);
    animation: tooltipAppear 0.15s ease;
}

.tooltip-bottom {
    top: calc(100% + 10px);
}

.tooltip-top {
    bottom: calc(100% + 10px);
}
