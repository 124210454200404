.side-nav-logo {
    width: 100%;
    height: 48px;
    object-fit: contain
}

.side-nav-logo-logo-only {
    align-self: center;
    justify-self: center;
    height: 128px;
}