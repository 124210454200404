.color-picker {
    padding: 2px;
    border: none;
    overflow: hidden;
    width: 100px;
}

@media screen and (any-pointer) {
    .color-picker:hover,
    .color-picker:active {
        cursor: pointer;
    }
}